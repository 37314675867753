import React, { useState } from "react"
import { graphql } from "gatsby"
import { Col, Container, Navbar, Row, Button } from "react-bootstrap"
import Layout from "../components/layout"
import SEO from "../components/seo"
import ShareButtons from "../components/shareButtons"
import AdBanner from "../components/adBanner"

export default function PageDetail({ location, data }) {
  const page = data.markdownRemark

  function fullTitle() {
    return page.frontmatter.title + " medical test"
  }

  function seoTitle() {
    return (
      "Learn about " +
      page.frontmatter.title.toLowerCase() +
      " medical tests in our medical check up knowledge center."
    )
  }

  const url = data.site.siteMetadata.siteUrl + location.pathname
  const twitterHandle = ""
  const tags = ""

  return (
    <Layout>
      <SEO title={seoTitle()} description={page.frontmatter.description} />
      <Container>
        <Row>
          <Col>
            <Row>
              <Col xs={12}>
                <h3>{fullTitle()}</h3>
                <Row>
                  <Col>
                    <p>{page.frontmatter.description}</p>
                  </Col>
                </Row>
              </Col>
            </Row>
            <hr />
            <Row className="justify-content-center">
              <AdBanner />
            </Row>
            <Row className="mb-5">
              <Col xs={12}>
                <div dangerouslySetInnerHTML={{ __html: page.html }} />
              </Col>
            </Row>
          </Col>
        </Row>
        <Row>
          <Col>
            <Navbar fixed="bottom" className="justify-content-center bg-dark">
              <h5 className="text-muted mr-sm-2">Share </h5>
              <div className="mr-auto">
                <ShareButtons
                  title={fullTitle()}
                  url={url}
                  twitterHandle={twitterHandle}
                  tags={tags}
                />
              </div>
              <Button variant="outline-primary" className="mr-sm-2" href="/">
                Home
              </Button>
            </Navbar>
          </Col>
        </Row>
      </Container>
    </Layout>
  )
}

export const query = graphql`
  query($slug: String!) {
    site {
      siteMetadata {
        siteUrl
      }
    }
    markdownRemark(fields: { slug: { eq: $slug } }) {
      html
      frontmatter {
        title
        description
        date(formatString: "DD MMMM, YYYY")
      }
      excerpt
    }
  }
`
